@font-face {
    font-family: 'Impact';
    src: local('Impact'),
        url('Impact.woff2') format('woff2'),
        url('Impact.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

