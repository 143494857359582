@tailwind base;
@tailwind components;
@tailwind utilities;





@import url("../font/stylesheet.css");
@import url("fontello.css");
@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-300.eot");
  src: url("../pr/Proxima-Nova-300.eot") format("eot"),
    url("../pr/Proxima-Nova-300.woff2") format("woff2"),
    url("../pr/Proxima-Nova-300.woff") format("woff"),
    url("../pr/Proxima-Nova-300.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-Regular.eot");
  src: url("../pr/Proxima-Nova-Regular.eot") format("eot"),
    url("../pr/Proxima-Nova-Regular.woff2") format("woff2"),
    url("../pr/Proxima-Nova-Regular.woff") format("woff"),
    url("../pr/Proxima-Nova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-Semibold.eot");
  src: url("../pr/Proxima-Nova-Semibold.eot") format("eot"),
    url("../pr/Proxima-Nova-Semibold.woff2") format("woff2"),
    url("../pr/Proxima-Nova-Semibold.woff") format("woff"),
    url("../pr/Proxima-Nova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PR";
  src: url("../pr/Proxima-Nova-Bold.eot");
  src: url("../pr/Proxima-Nova-Bold.eot") format("eot"),
    url("../pr/Proxima-Nova-Bold.woff2") format("woff2"),
    url("../pr/Proxima-Nova-Bold.woff") format("woff"),
    url("../pr/Proxima-Nova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
.translate-y-4 {
  transform: translateY(1rem);
}

/* Conversion */
.translate-y-9 {
  transform: translateY(0.95rem);
  margin-left: 1%;
}
.pagination {
  @apply flex justify-center w-full my-4;
}

.pagination li {
  @apply mr-0 md:mr-2;
}

.pagination li a {
  @apply block w-12 h-9 text-center leading-9 text-dlabelColor cursor-pointer border border-transparent;
}

.pagination li a:hover,
.active-pagination a {
  @apply bg-white border border-dblack !important;
}

.category-pagination {
  @apply flex justify-center w-full my-4;
}

.category-pagination li {
  margin: 0px 5px;
}

.category-pagination li a {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 200;
  color: rgb(126, 133, 155);
  text-align: center;
  font-size: 18.66px;
  text-decoration: none;
}

.category-pagination li a:hover,
.active-pagination-category a {
  border-radius: 3px;
  color: rgb(56 102 223) !important;
  background-color: white;
  box-shadow: rgb(56 102 223) 0px 0px 1px 1px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chart-container {
  width: 100%;
  overflow-x: auto;
}

.chart-wrapper {
  width: 1000px;
}

@media (min-width: 769px) {
  .chart-wrapper {
    width: 100%;
  }
}
